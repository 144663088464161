<template>
	<Idle v-if="state === 'idle'"></Idle>
	<Quiz v-if="state === 'quiz'"></Quiz>
	<Results v-if="state === 'results'"></Results>
</template>

<script setup>
import { useRoute } from 'vue-router'

import Idle from './Idle'
import Quiz from './Quiz'
import Results from './Results'

const route = useRoute()
let state = $computed(() => route.name)

</script>

<style lang="scss">

@import '../scss/main.scss';

</style>
