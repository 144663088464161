<template>

	<metainfo></metainfo>

	<FlowForm
		:questions="questions"
		:language="language"
		@submit="submit">
	</FlowForm>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import { cloneDeep, countBy } from 'lodash'
import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LanguageModel } from '@ditdot-dev/vue-flow-form'

const router = useRouter()

const questions = [
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Вигуляти песика',
				value: 'nature',
			}),
			new ChoiceOption({
				label: 'Лагодити велосипед, електросамокат',
				value: 'tech',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Провідати хворого друга',
				value: 'human',
			}),
			new ChoiceOption({
				label: 'Сидіти та кодити, складати таблиці або схеми',
				value: 'sign',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Фіксити дизайн листівок або плакатів',
				value: 'artist',
			}),
			new ChoiceOption({
				label: 'Доглядати та вчасно поливати домашні рослини',
				value: 'nature',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Майструвати меблі або шити одяг',
				value: 'tech',
			}),
			new ChoiceOption({
				label: 'Продавати товари іншим людям',
				value: 'human',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Обговорити наукову статтю з аміго',
				value: 'human',
			}),
			new ChoiceOption({
				label: 'Обговорити художню книгу з другом',
				value: 'artist',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Виводити нові сорти пшениці',
				value: 'nature',
			}),
			new ChoiceOption({
				label: 'Вдосконалювати машини, будинки чи одяг',
				value: 'tech',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Розбирати суперечки та сварки між людьми, переконувати',
				value: 'human',
			}),
			new ChoiceOption({
				label: 'Розбиратися в схемах чи таблицях (перевіряти, уточнювати)',
				value: 'sign',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Керувати творчим гуртком',
				value: 'artist',
			}),
			new ChoiceOption({
				label: 'Вивчати віруси та бактерії',
				value: 'nature',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Спроєктувати ракету та запустити її в космос',
				value: 'tech',
			}),
			new ChoiceOption({
				label: 'Допомагати людям при пораненнях, ударах чи опіках',
				value: 'human',
			}),
		],
	}),
	new QuestionModel({
		title: 'Що тобі подобається більше?',
		type: QuestionType.MultipleChoice,
		multiple: false,
		required: true,
		nextStepOnAnswer: false,
		options: [
			new ChoiceOption({
				label: 'Писати пости в Інста, бути блогером, знімати відоси на Тік ток',
				value: 'artist',
			}),
			new ChoiceOption({
				label: 'Складати точні описи та звіти про події',
				value: 'sign',
			}),
		],
	}),
]

const language = new LanguageModel({
	multipleChoiceHelpTextSingle: 'обери лише одну відповідь',
	pressEnter: 'тицяй :enterKey',
	enterKey: '↩',
	thankYouText: '🎉 чудово',
	submitText: 'то хто ж я?',
	percentCompleted: ':percent% пройдено',
})

function submit(questions) {
	let results = countBy(cloneDeep(questions), 'answer')

	router.push({
		name: 'results',
		query: results,
	})
}

useMeta({
	title: 'Що тобі подобається більше?',
	og: {
		image: 'https://хтоя.укр/обкладинка.png',
	},
})

</script>
