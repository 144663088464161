import { createApp } from 'vue'
import { MotionPlugin } from '@vueuse/motion'
import VueGtag from 'vue-gtag'
import router from './routes/router'
import metaManager from './plugins/vue-meta'
import App from './App.vue'
import './registerServiceWorker'

createApp(App)
	.use(router)
	.use(metaManager)
	.use(VueGtag, {
		config: {
			id: 'G-3PR4FGK3MK',
		},
	})
	.use(MotionPlugin)
	.mount('#app')

