export default [
	{
		id: 'nature',
		name: 'Ейнштейн',
		count: 0,
		image: 'Ейнштейн.png',
		description: 'тобі може підійти професія з природних наук - біолог, географ, геолог, математик, фізик, хімік, аграрні науки та інші професії ',
		subjects: {
			main: [
				'Біологія',
				'Математика',
			],
			optional: [
				'Історія',
				'Англійська мова',
			],
		},
	},
	{
		id: 'tech',
		name: 'Маск',
		count: 0,
		image: 'Маск.png',
		description: 'тобі підходить технічна спеціальність - інженер, оператора, проектувальник, спеціаліст з робототехніки, інформаційні технології',
		subjects: {
			main: [
				'Математика',
			],
			optional: [
				'Історія',
				'Англійська мова',
				'Хімія',
			],
		},
	},
	{
		id: 'human',
		name: 'Зеленський',
		count: 0,
		image: 'Зеленський.png',
		description: 'всі види професій, які передбачають взаємодію людей, наприклад, політика, релігія, педагогіка, психологія, медицина, торгівля, право',
		subjects: {
			main: [
				'Математика',
				'Історія',
			],
			optional: [
				'Англійська мова',
				'Хімія',
			],
		},
	},
	{
		id: 'sign',
		name: 'Джобс',
		count: 0,
		image: 'Джобс.png',
		description: 'професії, пов\'язані з обрахуванням, цифровими знаками, в тому числі музичні спеціальності, більшість професій пов\'язано з обробкою інформації (редактор, бухгалтер, економіст,  лінгвіст, філолог, історик, математик) ',
		subjects: {
			main: [
				'Математика',
				'Англійська мова',
			],
			optional: [
				'Історія',
				'Географія',
			],
		},
	},
	{
		id: 'artist',
		name: 'Поплавський',
		count: 0,
		image: 'Поплавський.png',
		description: 'різні види художньо-творчої праці, наприклад, література, музика, театр, образотворче мистецтво, дизайнер, журналіст',
		subjects: {
			main: [
				'Історія',
				'Математика',
			],
			optional: [
				'Англійська мова',
				'Творчий конкурс',
			],
		},
	},
]
