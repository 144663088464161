import { createWebHashHistory, createRouter } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'idle',
		component: Home,
	},
	{
		path: '/quiz',
		name: 'quiz',
		component: Home,
	},
	{
		path: '/results',
		name: 'results',
		component: Home,
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
