<template>

	<metainfo></metainfo>

	<h1 class="title is-1 has-text-centered has-margin-top-60"
		v-motion-pop>
		Ти - {{ type.name }}
	</h1>
	<div class="is-flex is-justify-content-center flex-column-mobile align-items-start">
		<figure class="image"
			v-motion-pop>
			<img
				:src="`assets/images/${type.image}`"
				:alt="type.id"
				style="
					max-height: 70vh;
					width: auto;
				}">
		</figure>
		<div class="has-margin-40 content"
			v-motion-pop>
			<p class="description">{{ type.description }}</p>

			<h3 class="title has-margin-top-30">Основні предмети</h3>
			<div class="tags are-medium">
				<span
					v-for="subject in mainSubjects"
					:key="subject"
					class="tag is-warning">
					{{ subject }}
				</span>
			</div>

			<h3 class="title has-margin-top-30">На вибір</h3>
			<div class="tags are-medium">
				<span
					v-for="subject in optionalSubjects"
					:key="subject"
					class="tag is-light">
					{{ subject }}
				</span>
			</div>

			<p class="has-margin-top-50">Хочеш, щоб ми допомогли тобі підготуватись до ЗНО з цих предметів? Тоді реєструйся на пробне онлайн (і навіть офлайн 😱) заняття у нашій школі. Тиць 👇</p>
			<a
				href="https://brames.com.ua/zno"
				class="button is-warning has-text-dark has-text-weight-bold has-padding-left-50 has-padding-right-50"
				v-motion-pop>
				☎️ Зареєструватись
			</a>

			<br>

			<router-link
				:to="{ name: 'quiz' }"
				tag="button"
				class="button is-dark is-small has-text-weight-bold has-margin-top-20 has-padding-left-20 has-padding-right-20"
				v-motion-pop>
				Пройти ще раз
			</router-link>
		</div>
	</div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useMeta } from 'vue-meta'
import { map, values, merge, cloneDeep, keyBy, maxBy, lowerCase } from 'lodash'
import personTypes from '../data/personTypes'

const route = useRoute()
let queryResults = $computed(() => route.query)
let results = $computed(() => map(queryResults, (count, id) => ({
	id,
	count,
})))

let types = $computed(() => values(merge(
	keyBy(personTypes, 'id'),
	keyBy(results, 'id'),
)))

const type = $computed(() => maxBy(cloneDeep(types), 'count'))
const mainSubjects = $computed(() => type?.subjects?.main)
const optionalSubjects = $computed(() => type?.subjects?.optional)

const computedMeta = $computed(() => useMeta({
	title: `Ти - ${type.name}`,
	description : type.description,
	og: {
		image: `https://хтоя.укр/обкладинка-${lowerCase(type.name)}.png`,
	},
}))
useMeta(computedMeta)

</script>

<style scoped>

.content p {
	max-width: 30em;
}

</style>
